import { OnlineBill, ScannedBill, StateLineItem } from "./types";

export function ucfirst(value: string) {
  const input = value.toLocaleLowerCase();
  return input.charAt(0).toUpperCase() + input.substring(1);
}

export function isNumber(value: number | string): value is number {
  return typeof value === "number" && !Number.isNaN(value) && Number.isFinite(value);
}

export function tryParseFloat(value: string) {
  const normalizedVal = value.replaceAll(",", ".");
  const retVal = parseFloat(normalizedVal);

  return isNumber(retVal) ? retVal : value;
}

export function formatCurrency(value: string | number, currencyCode: string) {
  const currencyFormatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: currencyCode,
  });
  const parsedValue = typeof value === "string" ? tryParseFloat(value) : value;

  return typeof parsedValue !== "string" ? currencyFormatter.format(parsedValue) : parsedValue;
}

export function formatCurrencyWithoutSymbol(value: string | number) {
  const parsedValue = typeof value === "string" ? tryParseFloat(value) : value;

  return typeof parsedValue !== "string"
    ? parsedValue.toLocaleString("nl-NL", { minimumFractionDigits: 2 })
    : parsedValue;
}

export function parseScannedItemsToState(input: ScannedBill["line_items"]): StateLineItem[] {
  return input.map(element => {
    const { amount, total_price } = element;
    const unit_price = total_price / amount;

    return {
      description: element.description,
      unit_price: formatCurrencyWithoutSymbol(unit_price),
      total_price: formatCurrencyWithoutSymbol(total_price),
      amount: String(amount),
      is_deleted: false,
    };
  });
}

export function parseToSubmittingValues(input: StateLineItem[]) {
  return input.map(element => ({
    ...element,
    unit_price: tryParseFloat(element.unit_price),
    total_price: tryParseFloat(element.total_price),
    amount: tryParseFloat(element.amount),
  }));
}

export function sum(value: number[]) {
  return value.reduce((accumulator, current) => accumulator + current, 0);
}

export function parseServerToSelectionState(bill: OnlineBill) {
  const stateItems = bill.line_items.map(element => {
    const payment_items = bill.payment_items
      .map(payment_item => {
        const line_items = payment_item.line_items.filter(payment => payment.line_item_index === element.index);

        return {
          creator: payment_item.creator,
          amount: sum(line_items.map(item => item.amount)),
        };
      })
      .filter(payment_item => payment_item.amount > 0);

    const current_amount = 0;
    const prior_amount = sum(payment_items.map(payment_item => payment_item.amount));
    const remaining_amount = Math.round((element.amount - current_amount - prior_amount) * 10) / 10;

    return {
      ...element,
      current_amount,
      prior_amount,
      remaining_amount,
      payment_items,
      mode: "undecided",
      splitting_denumerator: undefined,
    };
  });

  return [...stateItems].sort((a, b) => {
    if (b.remaining_amount === 0 && a.remaining_amount !== 0) {
      return -1;
    }

    if (a.remaining_amount === 0 && b.remaining_amount !== 0) {
      return 1;
    }

    return a.index - b.index > 0 ? 1 : -1;
  });
}

export function round(value: number, fractions = 1) {
  return Math.round(value * (fractions * 10)) / (fractions * 10);
}

export function formatDate(date: number) {
  return new Date(date * 1000).toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function formatFractional(value: number) {
  const integer = Math.floor(value);
  const fraction = round(value - integer, 2);
  let formatted = `${Math.floor(fraction * 10)}/10`;

  if (fraction === 0) {
    return integer.toString();
  }

  if (fraction === 0.35) {
    formatted = `1/3`;
  }

  if (fraction === 0.5) {
    formatted = "1/2";
  }

  if (fraction === 0.65) {
    formatted = "2/3";
  }

  return integer !== 0 ? `${integer} ${formatted}` : formatted;
}

export function extractPaymentMethod(bill: Pick<OnlineBill, "payment_method">) {
  return bill.payment_method.startsWith("http://") || bill.payment_method.startsWith("https://")
    ? { isUrl: true, value: new URL(bill.payment_method)?.host }
    : { isUrl: false, value: bill.payment_method };
}

export function extractFirstUrl(text: string) {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/;

  // Match the first URL in the text
  const match = text.match(urlRegex);

  // Return the matched URL or null if no URL is found
  return match ? match[0] : null;
}
